<template>
  <div class="sale" style="height: 100%;padding-bottom: 1.5625rem;">
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      style="height: calc(100% - 1.25rem); overflow-y: auto;"
    >
      <van-form class="sale_form" @submit="onSubmit">
        <van-row type="flex" justify="center">
          <van-col span="22">
            <van-field
              readonly
              clickable
              name="picker"
              :value="form.game"
              label="选择游戏"
              placeholder="请选择"
              :left-icon="form.gameicon"
              @click="changeClick(3)"
            />
            <van-popup
              v-model="form.gamePicker"
              position="bottom"
              duration="0.15"
            >
              <van-picker
                show-toolbar
                value-key="channelName"
                :columns="form.games"
                @confirm="popConfirm"
                @cancel="form.gamePicker = false"
              />
            </van-popup>
          </van-col>
          <van-col span="22">
          <van-field
              readonly
              clickable
              name="picker"
              :value="form.region"
              label="选择大区"
              placeholder="请选择"
              :left-icon="form.gameicon"
              @click="changeClick(5)"
            />
            <van-popup
              v-model="form.regionPicker"
              position="bottom"
              duration="0.15"
              get-container="body"
            >
              <van-picker
                show-toolbar
                value-key="regione_name"
                :columns="form.regions"
                @confirm="popConfirm"
                @cancel="form.regionPicker = false"
              />
            </van-popup>
          </van-col>
          <van-col span="22">
            <van-field
              readonly
              clickable
              name="picker"
              :value="form.server"
              label="选择区服"
              placeholder="请选择"
              :left-icon="form.servericon"
              @click="changeClick(1)"
            />
            <van-popup
              v-model="form.serverPicker"
              position="bottom"
              duration="0.15"
            >
              <van-picker
                show-toolbar
                value-key="server_name"
                :columns="form.servers"
                @confirm="popConfirm"
                @cancel="form.serverPicker = false"
              />
            </van-popup>
          </van-col>
          <van-col span="22">
            <van-field
              readonly
              clickable
              name="picker"
              :value="form.role"
              label="选择角色"
              placeholder="请选择"
              :left-icon="form.roleicon"
              @click="changeClick(2)"
            />
            <van-popup
              v-model="form.rolePicker"
              position="bottom"
              duration="0.15"
            >
              <van-picker
                show-toolbar
                value-key="chrname"
                :columns="form.roles"
                @confirm="popConfirm"
                @cancel="form.rolePicker = false"
              />
            </van-popup>
          </van-col>
          <van-col span="22">
            <van-field
              readonly
              clickable
              name="picker"
              :value="form.position"
              label="选择存放位置"
              placeholder="请选择"
              :left-icon="form.positionicon"
              @click="changeClick(4)"
            />
            <van-popup
              v-model="form.positionPicker"
              position="bottom"
              duration="0.15"
            >
              <van-picker
                show-toolbar
                value-key="positionname"
                :columns="form.positions"
                @confirm="popConfirm"
                @cancel="form.positionPicker = false"
              />
            </van-popup>
          </van-col>
        </van-row>
        <div class="price_w">
          <van-row type="flex" justify="center">
            <div
              v-show="form.position"
              style="display: flex;justify-content: center;flex-wrap: wrap;"
            >
              <div class="item_wrap">
                <van-col span="22">
                  <div class="item_w" v-if="form.itemList.length">
                    <div
                      v-for="item in form.itemList"
                      :key="item.id"
                      class="item_box"
                      :class="{ item_curr: item.isActive }"
                      @click="itemClick(item)"
                    >
                      <!-- <img src="../../assets/images/box_update/94.png" alt="" v-if="form.itemname == '元宝'"/> -->
                      <img :src="item.imgSrc" alt=""/>
                      <span v-if="item.isActive">{{ item.num }}</span>
                    </div>
                  </div>
                  <van-empty
                    :description="form.position + '空'"
                    image-size="70"
                    v-else
                  />
                </van-col>
              </div>
              <van-col span="22">
                <van-row class="detail">
                  <van-col span="13" class="detail_l">
                    <p>*注:单击选中,双击查看物品详情</p>
                  </van-col>
                  <van-col span="11" class="detail_r">
                    <span>物品属性延迟说明</span>
                    <van-popover
                      v-model="showDelayPopover"
                      trigger="click"
                      theme="dark"
                      placement="top-end"
                      :get-container="getContainer"
                    >
                      <div class="pop_wrap">
                        <p>
                          物品数据的传输中间可能会产生些许的延迟，导致预览物品详情时展示属性非游戏内最新的数据，物品实际以游戏内最新数据为准。
                        </p>
                        <p>
                          提示：登录角色后下线时即可获取到角色携带物品最新数据。
                        </p>
                      </div>
                      <template #reference>
                        <van-icon
                          name="question-o"
                          class="iconw"
                          size="1.3rem"
                        />
                      </template>
                    </van-popover>
                  </van-col>
                </van-row>
              </van-col>
              <van-col span="22">
                <van-row>
                  <van-col span="12" class="item_name">
                    <van-field
                      center
                      label=""
                      v-model="form.itemname"
                      readonly
                      placeholder="物品名称"
                    />
                  </van-col>
                  <van-col
                    span="12"
                    class="item_count"
                    style="position:relative"
                  >
                  <!-- :disabled="form.itemcount==''" -->
                    <van-field
                      center
                      label=""
                      v-model="form.itemcount"
                      placeholder="请填写出售数量"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @blur="saleCountInp"
                      @input="saleInput"
                    />
                    <span
                      v-if="form.itemname == '仙玉' || form.itemname == '元宝'"
                      style="font-size: 1rem;position: absolute;top: 50%;right: 2%;transform: translate(0, -50%);"
                      >万</span
                    >
                  </van-col>
                  <!-- <van-col v-if="form.itemname=='仙玉'" span="1" class="item_count" style="padding: 10.3125px 3px;border-radius: 3px;font-size: 1rem;">
                                        万
                                    </van-col> -->
                </van-row>
                <van-row v-if="form.itemname == '仙玉' || form.itemname == '元宝'">
                  <van-col span="12" class="item_name"> </van-col>
                  <van-col
                    span="12"
                    class="item_count"
                    style="position:relative"
                  >
                    <div style="color:#ee0a24">{{form.itemname}}售出最低1万</div>
                  </van-col>
                </van-row>
              </van-col>
              <van-col span="22">
                <van-field
                  label="验证手机号："
                  v-model="form.phone"
                  readonly
                  error-message="验证手机为账号绑定手机，无法修改"
                />
              </van-col>
              <van-col span="22" class="price_y">
                <van-field
                  v-model="form.yzm"
                  center
                  label="手机验证码："
                  oninput="value=value.replace(/[^\d]/g,'')"
                  placeholder="请输入验证码"
                >
                  <template #button>
                    <GetYzmCode :username="phone" kind="2"></GetYzmCode>
                  </template>
                </van-field>
              </van-col>
            </div>
            <van-col span="22" class="price_p" v-if="form.itemType == 3 ">
              <van-field
                center
                label=""
                v-model="form.price"
                oninput="value=value.replace(/[^\d]/g,'')"
                @keyup="getmoneyInp"
                placeholder="请输入出售总价"
                :disabled="form.itemcount==''"
              />
            </van-col>
            <van-col span="22" class="price_p" v-if="form.itemType == 2 && form.itemname != '仙玉' && form.itemname != '元宝'">
              <van-field
                center
                label=""
                v-model="form.price"
                oninput="value=value.replace(/[^\d]/g,'')"
                @keyup="getmoneyInp"
                placeholder="请输入出售总价"
                :disabled="form.itemcount==''"
              />
            </van-col>
<!--            <div class="xian_yu" v-if="form.itemname == '仙玉'" >-->
<!--                  <div class="xian_yu_scale">-->
<!--                      <van-field-->
<!--                        readonly-->
<!--                        clickable-->
<!--                        name="picker"-->
<!--                        :value="form.scale"-->
<!--                        label="选择出售比例"-->
<!--                        placeholder="点击选择上架比例"-->
<!--                        @click="showPicker = true"-->
<!--                          />-->
<!--                        <van-popup v-model="showPicker" position="bottom">-->
<!--                          <van-picker-->
<!--                            show-toolbar-->
<!--                            :columns="form.groundingScale"-->
<!--                            @confirm="onConfirm"-->
<!--                            @cancel="showPicker = false"-->
<!--                              />-->
<!--                        </van-popup>-->
<!--                        </div>-->
<!--                    </div>-->
                <div v-if="form.itemname == '元宝'|| form.itemname =='仙玉'" class="bing_xue_calc_box">
                  <span style="padding-right:20px">出售比例：</span>1： <input type="text" v-model="form.yuan_bao_scale" @input='yuanBaoInputInput' @change='yuanBaoInputChange' class="yuan_bao_input" placeholder="10" /><span style="padding: 0 10px;font-size: 13px;">请输入10的整数倍</span>
                  </div>
            <!-- <van-col span="8" class="price_p input_Trade" v-if="form.itemType==2">
                           <span class="span_Trade">最新交易比：{{proportion}}</span>
                           <span class="span_Trade">当前交易比：1:<span v-if="!form.price">0</span><span v-else>{{(form.itemcount/form.price ).toFixed(2)}}</span></span>
                       </van-col> -->
          </van-row>
        </div>
        <van-row type="flex" justify="center" class="van_rule">
          <van-col span="22">
            <van-row>
               <van-col span="12" class="sale_money" v-show="isGuidePrice">
                <p>
                  出售总价：<span>¥{{guidance_price}}</span>
                </p>
              </van-col>
              <van-col span="12" class="sale_money">
                <p>
                  到手价：<span>¥{{ form.saleMoney }}</span>
                </p>
              </van-col>
              <van-col span="12">
                <van-field name="checkbox" label="" class="rule_w" >
                  <template #input>
                    <van-checkbox v-model="form.ruleCheck" shape="round"
                      >已阅读上架规则</van-checkbox
                    >
                  </template>
                  <template #button>
                    <van-popover
                      v-model="showPopover"
                      trigger="click"
                      theme="dark"
                      :placement="isGuidePrice==false ? 'top-end' : 'top'"
                      :get-container="getContainer"
                      @open="isOpen"
                    >
                      <div class="pop_wrap" v-show="isShowOpen">
                        <div v-if="form.channelId==33" class="pop_wrap_div">
                          <p>
                            1、物品成功售出后平台将收取部分信息费。物品交易收费规则：
                            <!-- 信息费(装备)＝交易额×{{ruleData.equipmentSellingInformation}}%，最低为{{ruleData.equipmentInformationMin}}元，最高为{{ruleData.equipmentInformationMax}}元； -->
                            信息费 (仙玉/元宝)= 交易额 x
                            {{ ruleData.currencySellingInformation+'/'+ruleData.yuanbaoSellingInformation }}%，最低为{{
                              ruleData.currencyInformationMin+'/'+ruleData.yuanbaoInformationMin
                            }}元，最高为{{ ruleData.currencyInformationMax+'/'+ruleData.yuanbaoInformationMax }}元
                          </p>
                          <p>
                            2、物品申请上架后需经历审核期、出售期。 <br />
                            审核期：上架登记后需经过客服人工审核，通过审核后才是成功上架。<br />
                            <!-- 公示期：装备为{{
                              ruleData.equipmentPublicityPeriod
                            }}分钟，(仙玉/元宝)为{{
                              ruleData.currencyPublicityPeriod+'/'+ruleData.yuanbaoPublicityPeriod
                            }}小时，期间商品只做展示，不可购买。<br /> -->
                            出售期：(仙玉/元宝)出售期为{{ruleData.currencysalePeriod+'/'+ruleData.yuanbaoSalePeriod}}天，到期未出售则自动下架。
                            <!-- 装备出售期为{{ruleData.salePeriod}}天， -->
                          </p>
                        </div>
                        <div v-else class="pop_wrap_div">
                          <p>
                            1、物品成功售出后平台将收取部分信息费。物品交易收费规则：信息费
                            (装备)＝交易额×{{
                              ruleData.equipmentSellingInformation
                            }}%，最低为{{
                              ruleData.equipmentInformationMin
                            }}元，最高为{{
                              ruleData.equipmentInformationMax
                            }}元；信息费 (货币)= 交易额 x
                            {{ ruleData.currencySellingInformation }}%，最低为{{
                              ruleData.currencyInformationMin
                            }}元，最高为{{ ruleData.currencyInformationMax }}元
                          </p>
                          <p>
                            2、物品申请上架后需经历审核期、公示期、出售期。 <br />
                            审核期：上架登记后需经过客服人工审核，通过审核后才是成功上架，进入公示期。<br />
                            公示期：装备为{{
                              ruleData.equipmentPublicityPeriod
                            }}分钟，货币为{{
                              ruleData.currencyPublicityPeriod
                            }}小时，期间商品只做展示，不可购买。<br />
                            出售期：装备出售期为{{
                              ruleData.salePeriod
                            }}天，货币出售期为{{ruleData.currencysalePeriod}}天，到期未出售则自动下架。
                          </p>
                        </div>
                      </div>
                      <template #reference>
                        <van-icon
                          name="question-o"
                          class="iconw"
                          size="1.3rem"
                        />
                      </template>
                    </van-popover>
                  </template>
                </van-field>
              </van-col>
            </van-row>
          </van-col>
          <van-col span="21" class="sumbit_btn">
            <van-button
              round
              type="info"
              size="large"
              color="linear-gradient(to right, #FF704D, #F73727)"
              native-type="submit"
              >申请上架</van-button
            >
          </van-col>
        </van-row>
      </van-form>
    </van-pull-refresh>

    <div class="pop_wrap">
      <van-popup v-model="saleShow" position="top">
        <div class="pop_w">
          <p>
            申请上架后此物品将会进入封禁状态，取消申请或下架取回后解除封禁。
          </p>
          <p class="red">( 注：交易行内物品不予展示，请自行取回至背包 )</p>
        </div>
        <div class="pop_btns">
          <van-button
            round
            type="info"
            size="small"
            color="linear-gradient(to right, #FF704D, #F73727)"
            @click="saleClick"
            >确认</van-button
          >
          <van-button
            plain
            round
            type="default"
            size="small"
            @click="saleShow = false"
            >取消</van-button
          >
        </div>
      </van-popup>
    </div>
    <ItemDetail
      :detailObj1="detailObj"
      :loading="loading"
      :wrapperShow="wrapperShow"
      @popShow="popShowClick"
    ></ItemDetail>
    <van-dialog v-model="show" title="提示" theme='round-button' confirmButtonText="知道了">
      <!-- <div>
        <img style="width:3rem;padding-top: 8px;" src="@/assets/images/200069.png" />
      </div> -->
      <div style="color: #646566;padding: 6px 24px;font-size: 14px;line-height: 20px;">{{dialog_mes}}</div>

    </van-dialog>
  </div>
</template>
<script>
import ItemDetail from "@/components/itemDetail";
import GetYzmCode from "@/components/getYzmCode";

export default {
  name: "Saleitems",
  components: {
    ItemDetail,
    GetYzmCode,
  },
  data() {
    return {
      xian_yu_truefee:'',
      showPicker:false,
      guidance: '',
      guidance_price:0,
      isGuidePrice:false,
      dialog_mes:'',
      show:false,
      isShowOpen: "false",
      form: {
        scale:'',
        yuan_bao_scale:'',
        groundingScale:[],
        groundingScaleData:[],
        game: "",
        gtype:'',
        channelId: "",
        games: [],
        gamePicker: false,
        gameicon: require("../../assets/images/sale_icon1.png"),
        region:'',
        regione_id:'',
        regionPicker:false,
        regions:[],
        regionicon: '',
        server: "",
        server_id: "",
        servericon: require("../../assets/images/sale_icon2.png"),
        serverPicker: false,
        servers: [],
        role: "",
        seedname: "",
        roleicon: require("../../assets/images/sale_icon3.png"),
        rolePicker: false,
        roles: [],
        priceicon: require("../../assets/images/sale_icon6.png"),
        priceCheck: false,
        position: "",
        position_id: "",
        xianYuNum:'',
        positions: [
          {
            positionname: "背包",
            position_id: 1,
          },
          {
            positionname: "仓库",
            position_id: 0,
          },
        ],
        positionicon: require("../../assets/images/sale_icon7.png"),
        positionPicker: false,
        price: "",
        ruleCheck: false,
        itemname: "",
        itemcount: '',
        saleMoney: 0,
        goods_id: "",
        itemList: [],
        itemType: 2,
        chrname: "",
        phone: "",
        yzm: "",
      },
      showPopover: false,
      showDelayPopover: false,
      saleShow: false,
      ruleData: {
        equipmentSellingInformation: 0,
        equipmentInformationMin: 0,
        equipmentInformationMax: 0,
        currencySellingInformation: 0,
        currencyInformationMin: 0,
        currencyInformationMax: 0,
        equipmentPublicityPeriod: 0,
        currencyPublicityPeriod: 0,
        salePeriod: 0,
      },
      isLoading: false,
      touchtime: new Date().getTime(),
      detailObj: {},
      loading: true,
      wrapperShow: false,
      itemCount: 1,
      phone: "",
      yzmCode: "",
      isTrue: false,
      proportion: "1:0",
      separateOnsaleType:''
    };
  },
  created() {
    // this.$dialog.alert({
    //   title: '标题',
    //   message: '弹窗内容',
    //   theme: 'round-button',
    // }).then(() => {
    //   // on close
    // });
    let data = sessionStorage.getItem("a");
    // alert(sessionStorage.getItem("a"))
    if (data) {
      data = JSON.parse(data);
      data.channel_id = data.type;
      this.isTrue = true;
      this.$api.sale
        .newest_currency_order({ channel_id: data.type })
        .then((res) => {
          if (res.code == 200) {
            this.proportion = res.data.proportion;
          }
        });
    } else {
      data = false;
    }
    if (!localStorage.getItem("token")) {
      return false;
    }
    //获取绑定的手机号
    this.phone = localStorage.getItem("username")
      ? localStorage.getItem("username")
      : "";
    this.form.phone = this.phone.substr(0, 3) + "****" + this.phone.substr(7);
    //获取渠道列表
    // channel_id=18&server_id=50002
    // {"password":"","server_id":"50001","type":"18","username":"3119855","seedname":"50001165831154173196"}
    // let data = {
    //     channel_id:18,
    //     server_id:50001,
    //     seedname: "50001165692302579564"
    // }
    this.gameChannelList(data);
  },
  methods: {
    yuanBaoInputInput(e){
        this.form.yuan_bao_scale = this.form.yuan_bao_scale.replace(/[^\d]/g,'')
        this.guidance=this.form.yuan_bao_scale;
        this.guidance_price = Math.floor(Number(this.form.itemcount * 10000 / this.guidance)*100 )/100;  //出售总价
        let infofee = 0,
        truefee = 0;
        if (this.separateOnsaleType==4) {
          //无限刀2--元宝
          infofee =this.guidance_price * this.ruleData.yuanbaoSellingInformation * 0.01;
          if (infofee < this.ruleData.yuanbaoInformationMin) {
            truefee = this.ruleData.yuanbaoInformationMin;
          } else if (infofee > this.ruleData.yuanbaoInformationMax) {
            truefee = this.ruleData.yuanbaoInformationMax;
          } else {
            truefee = infofee;
          }
        }else if(this.separateOnsaleType==2){
          // 其他游戏的仙玉/元宝
          infofee =this.guidance_price * this.ruleData.currencySellingInformation * 0.01;
          if (infofee < this.ruleData.currencyInformationMin) {
            truefee = this.ruleData.currencyInformationMin;
          } else if (infofee > this.ruleData.currencyInformationMax) {
            truefee = this.ruleData.currencyInformationMax;
          } else {
            truefee = infofee;
          }
        }
        this.form.saleMoney =Math.floor( Number(this.guidance_price - truefee < 0 ? 0 : this.guidance_price - truefee)*100)/100;//到手价
        if (this.form.yuan_bao_scale==0) {
          this.guidance_price=0
          this.form.saleMoney=0
        }
        
    },
    yuanBaoInputChange(){
       if (this.form.yuan_bao_scale%5!=0 || this.form.yuan_bao_scale==0) {
         this.$toast({
              message: "出售比例请输入10的整倍数",
              duration: 1500,
            });
       }
    },
    //上架比例列表 scale_list
        getScaleGoods(){
          let data=[];
         this.$api.shopping.scale_list({ channel_id: this.form.channelId }).then((res)=>{
         if (res.code==200) {
           data=res.data
          this.form.groundingScaleData=res.data;
          this.form.groundingScale = data.map((item)=>{
              return item.money_scale
           })
         }
        //  console.log(this.groundingScaleData);
       })
    },
    //仙玉选择比例
    // onConfirm(value){
    //     this.form.scale = value;
    //     let item=this.form.groundingScaleData.filter((item)=>item.money_scale==this.form.scale);
    //     this.guidance=item[0].rebate;
    //     // Math.floor(15.7784514000 * 100) / 100
    //     this.guidance_price = Math.floor(Number(this.form.itemcount * 10000 / this.guidance)*100 )/100;  //出售总价
    //     let infofee = 0,
    //     truefee = 0;
    //     infofee =this.guidance_price * this.ruleData.currencySellingInformation * 0.01;
    //     if (infofee < this.ruleData.currencyInformationMin) {
    //       truefee = this.ruleData.currencyInformationMin;
    //     } else if (infofee > this.ruleData.currencyInformationMax) {
    //       truefee = this.ruleData.currencyInformationMax;
    //     } else {
    //       truefee = infofee;
    //     }
    //     this.form.saleMoney =Math.floor( Number(this.guidance_price - truefee < 0 ? 0 : this.guidance_price - truefee)*100)/100;//到手价
    //     this.xian_yu_truefee=truefee;
    //     this.showPicker = false;
    //   },
    //下拉刷新
    onRefresh() {
      //点击确认后页面重置
      Object.assign(this.$data.form, this.$options.data().form);
      if (!localStorage.getItem("token")) {
        return false;
      }
      //获取绑定的手机号
      this.phone = localStorage.getItem("username")
        ? localStorage.getItem("username")
        : "";
      this.form.phone = this.phone.substr(0, 3) + "****" + this.phone.substr(7);

      let data = sessionStorage.getItem("a");

      if (data) {
        data = JSON.parse(data);
        data.channel_id = data.type;
        this.isTrue = true;
        this.$api.sale
          .newest_currency_order({ channel_id: data.type })
          .then((res) => {
            if (res.code == 200) {
              // console.log(res)
              this.proportion = res.data.proportion;
            }
          });
      } else {
        data = false;
      }
      //获取渠道列表
      this.gameChannelList(data);
      //获取交易规则
      this.getRule();
    },
    //获取交易规则信息
    getRule() {
      this.$api.sale
        .getRule({ channel_id: this.form.channelId })
        .then((res) => {
          this.isLoading = false;
          if (res.code == 200) {
            // console.log(res.data);
            this.ruleData = res.data;
          }
        });
    },
    //验证时候登录
    isToken() {
      let token = localStorage.getItem("token");
      if (!token) {
        this.$router.push({
          name: "Login",
        });
      }
    },
    //返回一个特定的 DOM 节点，作为挂载的父节点,挂在气泡框
    getContainer() {
      return document.querySelector(".van_rule");
    },
    //提交申请
    onSubmit() {
      // console.log(this.form.itemType==2 , (this.form.itemcount/this.form.price).toFixed() >200)
      this.isToken();
      if (!localStorage.getItem("token")) {
        return false;
      }
      if (this.form.role) {
        if (this.form.position) {
          if (!this.form.itemname) {
            this.$toast({
              message: "请选择存放位置后再选中需上架的物品",
              duration: 1500,
            });
            return false;
          }
          // if (!this.form.yzm.trim()) {
          //   this.$toast({
          //     message: "请填写正确的验证码",
          //     duration: 1500,
          //   });
          //   return false;
          // }
          if (this.form.itemType == 2) {
            //货币
            // if (this.form.price < this.ruleData.currencyInformationMin) {
            //   this.$toast({
            //     message: "价格不能低于最低出售信息费",
            //     duration: 1500,
            //   });
            //   return false;
            // }
            if (this.form.itemname=='仙玉') {
              if (this.form.yuan_bao_scale=='') {
              this.$toast({
                message: "请选择仙玉出售比例",
                duration: 1500,
              });
              return false;
            }
            }else if(this.form.itemname=='元宝'){
              if (this.form.yuan_bao_scale=='') {
              this.$toast({
                message: "请输入元宝出售比例",
                duration: 1500,
              });
              return false;
             }
                if (this.form.yuan_bao_scale%5!=0 || this.form.yuan_bao_scale==0) {
                this.$toast({
                  message: "出售比例请输入10的整倍数",
                  duration: 1500,
                });
                return false;
                }
            }
          } else {
            //装备
            if (this.form.price < this.ruleData.equipmentInformationMin) {
              this.$toast({
                message: "价格不能低于最低出售信息费",
                duration: 1500,
              });
              return false;
            }
          }

          if (this.form.itemcount > Number(this.itemCount)) {
            this.$toast({
              message: "当前输入数量超出了可出售总数量",
              duration: 1500,
            });
            return false;
          }
          if ( this.form.itemType != 2 ) {
            if (!/^[1-9][\d]*$/.test(this.form.price.trim())) {
              this.$toast({
                message: "出售价格内不能含小数点",
                duration: 1500,
              });
              return false;
            }else if (this.form.price.trim() < this.informationMin) {
              this.$toast({
                message: "价格不能低于最低出售信息费",
                duration: 1500,
              });
            return false;
            }
          }

          if (this.form.ruleCheck) {
            // console.log(this.form.gtype);
            // console.log(this.form.itemcount);
            // console.log(this.xianYuNum);
            if (this.form.gtype==2) {
              if (this.form.itemcount * 10000 > this.xianYuNum) {
                this.$toast({
                  message: "当前输入数量超出了可出售总数量,请重新输入出售数量",
                  duration: 1500,
                });
                return false;
              }else{
                this.saleShow = true;
              }
            }
            this.saleShow = true;
          } else {
            this.$toast({
              message: "请先阅读上架规则并勾选",
              duration: 1500,
            });
          }
        } else {
          this.$toast({
            message: "请选择存放位置",
            duration: 1500,
          });
        }
      } else {
        this.$toast({
          message: "请选择申请上架角色",
          duration: 1500,
        });
      }

    },
    //确认提交
    saleClick() {
      let aaa;
      if(this.form.itemname=='元宝'||this.form.itemname=='仙玉' ){
        aaa=this.form.yuan_bao_scale
      }
      this.saleShow = false;
      let price = this.form.price=='' ? this.guidance_price:this.form.price;
      this.$api.sale.createEquipment({
          channel_id: this.form.channelId,
          server_id: this.form.server_id,
          seedname: this.form.seedname,
          type:this.separateOnsaleType,
          num: this.form.itemcount,
          goods_name: this.form.itemname,
          price: price,
          goods_id: this.form.goods_id,
          chrname: this.form.chrname,
          ptype: this.form.position_id,
          code: this.form.yzm,
          scale:aaa
        }).then((res) => {
          // this.form.scale='';
          // this.form.price='';
          // this.isGuidePrice=false;
          if (res.code==200) {
            this.$toast({
              message: "订单处理中，请一分钟后查看！",
              duration: 2000,
            });
            //点击确认后页面重置
            Object.assign(this.$data.form, this.$options.data().form);
            this.guidance_price='';
            //获取渠道列表
            this.gameChannelList();
          }else{
            if(res.code==10000){
              this.show = true;
              this.dialog_mes = res.msg
            }else{
              this.$toast({
                message: res.msg,
                duration: 1500,
              });
            }
          }
        });
    },
    //游戏渠道列表
    gameChannelList(data) {
      // console.log(data)
      this.$api.helper
        .getChannel()
        .then((res) => {
          this.isLoading = false;
          res.data.forEach((item,index)=>{
            if(item.channelId==24){
              res.data.splice(index,1)
            }
          })
          if (res.code == 200) {
            this.form.games = res.data;
          }
          // 寄售仙玉里的操作
          if (data) {
            if(data.type ==0){
              this.form.game = "仙境传奇";
            }else if(data.type ==33){
              this.form.game = "无限刀Ⅱ";
            }else if(data.type ==9){
              this.form.game = "时光倒流";
            }else{
              this.form.game = "无限刀";
            }
            this.form.channelId = data.type;
            this.form.gamePicker = false;
            this.regionList(data)
            //获取channelId对应规则
            this.getRule();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //根据渠道获取大区列表
    regionList(data) {
      this.$api.shopping.regionList({
          channel_id: this.form.channelId,
        }).then((res) => {
          if (res.code == 200) {
            this.form.regions = res.data;
          }
          if (data) {
            let regioneId=Math.floor(data.server_id/1000)
            for (var key in res.data) {
                if (res.data[key].regione_id == regioneId) {
                  this.form.region = res.data[key].regione_name;
                  this.form.regione_id = res.data[key].regione_id;
                }
              }
            // this.form.regione_id = data.type;
            // this.form.gamePicker = false;
             this.serverList(data);
          }
        }).catch((err) => {
          console.log(err);
        });
    },
    //根据渠道获取区服列表
    serverList(data) {
      this.$api.helper
        .getServerList({
          channel_id: this.form.channelId,
          regione_id:this.form.regione_id
        })
        .then((res) => {
          if (res.code == 200) {
            for (var key in res.data) {
              this.form.servers.push({
                server_name: res.data[key].server_name,
                server_id: res.data[key].server_id,
              });
            }
            if (data) {

              for (var key in res.data) {
                if (res.data[key].server_id == data.server_id) {
                  this.form.server = res.data[key].server_name;
                  this.form.server_id = res.data[key].server_id;
                }
              }

              // this.form.serverPicker = false;
              // this.form.role = '';
              // this.form.roles = [];
              // this.form.position = '';
              this.roleList(data);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //根据区服获取角色列表
    roleList(data) {
      var ch,sd;
      if(data){
        ch = data.type;
        sd = data.server_id;
      }else{
        ch = this.form.channelId;
        sd = this.form.server_id;
      }
      this.$api.helper
        .getPlayerList({
          channel_id: ch,
          server_id: sd,
          type: 1,
        })
        .then((res) => {
          if (res.code == 200) {
            this.form.roles = res.data;
            if (data) {
              // if(res.data)
              res.data.forEach((item) => {
                // console.log(data)
                if (item.seedname == data.seedname) {
                    this.form.role = item.chrname;
                    this.form.seedname = item.seedname;
                    this.form.chrname = item.chrname;
                    this.form.position = "背包";
                    this.form.position_id = 1;
                    this.getEquipmentList(data);
                  }
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //根据存放位置获取对应物品
    getEquipmentList(data) {
      this.$api.sale
        .getBagItems({
          channel_id: this.form.channelId,
          server_id: this.form.server_id,
          seedname: this.form.seedname,
          type: this.form.position_id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.form.itemList = res.data;
            this.form.itemList.forEach((item, index) => {
              this.$set(item, "isActive", false);
              var imgSrc = "";
              try {
                imgSrc = require("../../assets/images/itemicon/" +
                  this.form.channelId +
                  "/" +
                  item.icon_id +
                  ".png");
              } catch (e) {
                imgSrc = require("../../assets/images/question.png");
                console.log(e);
              }
              this.$set(item, "imgSrc", imgSrc);
            });
            if (data) {
              this.itemClick(this.form.itemList[0], true);
            }
            console.log();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // },
    //选择框确认
    popConfirm(val) {
      if (this.form.gamePicker) {
        if (this.form.games.length == 0) {
          this.form.gamePicker = false;
          return false;
        }
        //游戏切换
        this.form.game = val.channelName;
        this.form.channelId = val.channelId;
        this.form.gamePicker = false;
        this.form.server = "";
        this.form.region='';
        this.form.role = "";
        this.form.servers = [];
        this.form.roles = [];
        this.form.regions=[];
        this.form.position = "";
        //获取channelId对应规则
        this.getRule();
        this.regionList()
        }else if(this.form.regionPicker){
          if (this.form.regions.length == 0) {
            this.form.regionPicker = false;
            return false;
          }
          //大区切换
          this.form.region = val.regione_name;
          this.form.regione_id = val.regione_id;
          this.form.regionPicker = false;
          this.form.server = "";
          this.form.role = "";
          this.form.servers = [];
          this.form.roles = [];
          this.form.position = "";
          this.serverList();
      } else if (this.form.serverPicker) {
        if (this.form.servers.length == 0) {
          this.form.serverPicker = false;
          return false;
        }
        //区服切换
        this.form.server = val.server_name;
        this.form.server_id = val.server_id;
        this.form.serverPicker = false;
        this.form.role = "";
        this.form.roles = [];
        this.form.position = "";
        this.roleList();
      } else if (this.form.rolePicker) {
        if (this.form.roles.length == 0) {
          this.form.rolePicker = false;
          return false;
        }
        //角色切换
        this.form.role = val.chrname;
        this.form.seedname = val.seedname;
        this.form.chrname = val.chrname;
        this.form.rolePicker = false;
        this.form.position = "";
      } else {
        if (this.form.positions.length == 0) {
          this.form.positionPicker = false;
          return false;
        }
        //存放位置切换
        this.form.position = val.positionname;
        this.form.position_id = val.position_id;
        this.form.positionPicker = false;
        this.getEquipmentList();
      }
    },
    //选择框选择顺序提示
    changeClick(key) {
      this.isToken();
      if (!localStorage.getItem("token")) {
        return false;
      }
      if (this.isTrue) {
        if (key == 1 || key == 2 || key == 3 || key == 5) {
          return false;
        }
      }

      if (key == 1) {
        if (this.form.region) {
          this.form.serverPicker = true;
        } else {
          this.$toast({
            message: "请先选择大区，再选区服",
            duration: 1500,
          });
        }
      } else if (key == 2) {
        if (this.form.server) {
          this.form.rolePicker = true;
        } else {
          this.$toast({
            message: "请先选择区服，再选角色",
            duration: 1500,
          });
        }
      } else if (key == 4) {
        if (this.form.role) {
          this.form.positionPicker = true;
        } else {
          this.$toast({
            message: "请先选择角色，再选存放位置",
            duration: 1500,
          });
        }
      } else if (key == 3) {
        this.form.gamePicker = true;
        this.isGuidePrice=false;
      }else if(key==5){
        //大区
        if (this.form.game) {
          this.form.regionPicker = true;
        } else {
          this.$toast({
            message: "请先选择游戏，再选大区",
            duration: 1500,
          });
        }
      }
    },

    //获取售出价格
    getmoneyInp() {
      let infofee = 0,
        truefee = 0;
      if (this.form.itemType == 2) {
        infofee =
          this.form.price * this.ruleData.currencySellingInformation * 0.01;
        if (infofee < this.ruleData.currencyInformationMin) {
          truefee = this.ruleData.currencyInformationMin;
        } else if (infofee > this.ruleData.currencyInformationMax) {
          truefee = this.ruleData.currencyInformationMax;
        } else {
          truefee = infofee;
        }
      } else {
        infofee =
          this.form.price * this.ruleData.equipmentSellingInformation * 0.01;
        if (infofee < this.ruleData.equipmentInformationMin) {
          truefee = this.ruleData.equipmentInformationMin;
        } else if (infofee > this.ruleData.equipmentInformationMax) {
          truefee = this.ruleData.equipmentInformationMax;
        } else {
          truefee = infofee;
        }
      }
      this.form.saleMoney =
        this.form.price - truefee < 0 ? 0 : this.form.price - truefee;
    },
    //物品详情
    getItemDetail(goods_data) {
      this.$api.sale
        .getItemDetail({
          channel_id: this.form.channelId,
          server_id: this.form.server_id,
          seedname: this.form.seedname,
          goods_data: goods_data,
        })
        .then((res) => {
          if (res.code == 200 && res.data.length > 0) {
            this.detailObj = res.data[0];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //点击物品
    itemClick(item, type) {
      // console.log(item)
      //仙玉指导价
      if(item.gtype==2 || item.gtype==4){
        this.getScaleGoods();
        this.form.gtype=item.gtype;
        this.guidance='',
        this.form.itemcount=1;
        this.isGuidePrice=true;
        // this.$api.sale.getProportion({ channel_id: this.form.channelId,server_id: this.form.server_id}).then((res)=>{
          // console.log(res);
          this.xianYuNum=item.num;
          // this.guidance=res.data.guidance;
          // this.guidance_price = Math.ceil(Number(this.form.itemcount * 10000 / this.guidance) );
        // }).catch((error)=>{})
      }else{
        this.isGuidePrice=false;
      }
      // console.log(type);
       this.guidance_price=0
       this.form.saleMoney=0
       this.form.yuan_bao_scale=''
       this.form.price=''
      let data = "",
        obj = {};
      for (var key in item) {
        if (key != "imgSrc" && key != "isActive") {
          obj[key] = item[key];
        }
      }
      if (type) {
        this.form.itemcount = 1;
      }
      data = JSON.stringify(obj);
      this.itemCount = item.num;
      this.form.itemname = item.name;
      this.form.goods_id = item.type;
      //这个参数区分无限刀2的元宝
      this.separateOnsaleType=item.gtype
      if (item.gtype == 3) {
        this.form.itemType = 3;
        this.getItemDetail(data);
      }else {
        this.form.itemType = 2;
      }
      if (new Date().getTime() - this.touchtime < 500) {
        //console.log('dbclick');
        //双击
        if (item.gtype == 3) {
          this.wrapperShow = true;
          this.loading = false;
        }
      } else {
        //单击
        this.touchtime = new Date().getTime();
        // console.log('click');
        this.form.itemList.forEach((item) => {
          item.isActive = false;
        });
        item.isActive = true;
      }
    },
    //获取出售数量
    saleCountInp() {
      if (this.form.itemcount < 1) {
        this.form.itemcount = 1;
      } else if (this.form.itemcount > Number(this.itemCount)) {
        // this.form.itemcount = this.itemCount;
        this.$toast({
          message: "当前输入数量超出了可出售总数量",
          duration: 1500,
        });
      }
    },
    //根据输入获取指导价格
    saleInput(num){
      // console.log('num',num);
      // console.log('this.guidance ',this.guidance );
       if (num * 10000 > this.xianYuNum) {
          this.$toast({
          message: "当前输入出售数量超出了可出售总数量,请重新输入出售数量",
          duration: 1500,
        });
       }else{
       if(this.guidance != ''){
      //  this.guidance_price = Math.floor(Number(this.form.itemcount * 10000 / this.guidance)*100 )/100;  //出售总价
      //  this.form.saleMoney =Math.floor(Number(this.guidance_price - this.xian_yu_truefee < 0 ? 0 : this.guidance_price - this.xian_yu_truefee)*100)/100;
        this.guidance_price = Math.floor(Number(this.form.itemcount * 10000 / this.guidance)*100 )/100;  //出售总价
        let infofee = 0,
        truefee = 0;
        infofee =this.guidance_price * this.ruleData.currencySellingInformation * 0.01;
        if (infofee < this.ruleData.currencyInformationMin) {
          truefee = this.ruleData.currencyInformationMin;
        } else if (infofee > this.ruleData.currencyInformationMax) {
          truefee = this.ruleData.currencyInformationMax;
        } else {
          truefee = infofee;
        }
        this.form.saleMoney =Math.floor( Number(this.guidance_price - truefee < 0 ? 0 : this.guidance_price - truefee)*100)/100;//到手价
       }
      }
      // console.log('this.guidance_price',this.guidance_price);
      // console.log('this.form.saleMoney',this.form.saleMoney);
      // console.log('this.xian_yu_truefee',this.xian_yu_truefee);
    },
    //接收子组件弹窗关闭事件
    popShowClick(val) {
      this.wrapperShow = val;
    },
    //设置选择游戏后才能点开交易规则
    isOpen() {
      if (this.form.game == "") {
        this.isShowOpen = false;
        this.$toast({
          message: "请先选择游戏",
          duration: 1500,
        });
      } else {
        this.isShowOpen = true;
      }
    }
  }
};
</script>
<style>
.dialog_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sale_form {
  padding: 4% 0 15% 0;
}
.sale_form .van-col {
  margin-bottom: 3%;
}
.sale_form .van-cell {
  background-color: #efefef;
  border-radius: 0.09375rem;
  padding: 10px 2% 10px 15px;
}
.sale_form .van-field__label {
  color: #313131;
}
.sale_form .empower .van-field__label {
  width: 13em;
}
.sale_form .pricew .van-field__label {
  width: 13em;
}
.sale_form .pricew .van-field__label span {
  position: relative;
}
.sale_form .pricew .van-field__label span::after {
  content: "(可不选)";
  position: absolute;
  top: -0.125rem;
  right: -1.65625rem;
  color: #b1b1b1;
}
.price_w {
  border-top: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
}
.price_w .van-col {
  margin-bottom: 0;
}
.price_w .van-cell {
  background-color: #fff;
  padding: 0.3125rem 0;
}
.price_w .yzmcode_w button {
  top: 0;
}
.price_y input {
  background-color: #efefef;
  border-radius: 0.09375rem;
  width: 60%;
  padding: 2%;
}
.price_p input {
  background-color: #efefef;
  padding: 3% 2%;
  border-radius: 3px;
  font-size: 1rem;
  text-align: center;
}
.price_p .van-field__body {
  position: relative;
}
.price_p .van-field__body::after {
  content: "元";
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translate(0, -50%);
  font-size: 1rem;
}
.sale_form .sumbit_btn button {
  font-size: 1.2rem;
}
.sale_form .rule_w {
  background-color: #fff;
  padding: 10px 0;
}
.rule_w .van-field__body {
  justify-content: flex-end;
}
.rule_w .van-field__control {
  width: 100%;
}
.rule_w .van-field__button {
  display: flex;
  padding-left: 0;
}
.rule_w .van-popover__wrapper {
  display: flex;
}
.rule_w .van-checkbox__label {
  font-size: 13px;
}
/* 气泡框样式 */
.van_rule .van-popup {
  width: 20rem;
}
.van_rule .pop_wrap {
  /* background-color: #efefef; */
  text-align: left;
  padding: 3%;
  line-height: 1.6em;
}
.pop_wrap_div p{
  line-height: 1.6em;
}
/* .van_rule .pop_wrap p:first-child{
    color: #f00;
} */
.van_rule .van-popup .van-popover__arrow {
  display: none;
}
.van_rule .sale_money {
  text-align: left;
  padding: 10px 0;
  font-size: 13px;
  line-height: 24px;
}
.van_rule .sale_money span {
  color: #1ac917;
}
.item_name input,
.item_count input {
  background-color: #efefef;
  padding: 10px 3px;
  border-radius: 3px;
  font-size: 1rem;
  text-align: center;
}
.item_name input {
  width: 97%;
}
.span_Trade {
  display: block;
  width: 100%;
  text-align: left;
  text-indent: 10px;
}
.input_Trade {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.xian_yu .van-field__control{
  background-color: #efefef;
  padding: 4px 6px;
  border-radius: 2px;
}
</style>
<style scoped>
.van_rule .van-col {
  margin-bottom: -1px !important;
}
.price_w .item_wrap {
  border-bottom: 0.03125rem solid #bbb;
  display: flex;
  justify-content: center;
  width: 100%;
}
.price_w .item_w {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  max-height: 14rem;
  overflow-y: scroll;
}
.price_w .item_w .item_box {
  background-color: #949494;
  margin-right: 2%;
  margin-bottom: 2%;
  border-radius: 0.21875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.0625rem solid #949494;
  position: relative;
  padding: 1%;
  width: 18%;
  height: 3.8rem;
}
.price_w .item_w .item_box span {
  position: absolute;
  right: 0.03125rem;
  bottom: -0.09375rem;
  font-size: 0.375rem;
  color: #313131;
}
.price_w .item_w .item_curr {
  background-color: #fae3c8;
  border-color: #ff4e00;
}
.price_w .item_w .item_box:nth-child(5n + 0) {
  margin-right: 0;
}
.price_w .item_w .item_box img {
  width: 80%;
  max-width: 3.2rem;
}
.detail {
  padding: 16px 0 6.4px;
}
.detail .detail_l p {
  font-size: 13px;
  color: #ff4e00;
}
.detail .detail_r span {
  font-size: 13px;
  margin-right: 3px;
}
.detail .detail_r {
  display: flex;
  justify-content: right;
}
.xian_yu{
  padding: 8px;
  margin-right: 81px;
}
/* 冰雪货币交易 */
.yuan_bao_input{
  border: 1px #ccc solid;
  padding: 4px 10px;
  width: 110px;
  font-size: 1rem;
}
.bing_xue_calc_box{
  height: 50px;
  padding-top: 5px;
  font-size: 15px;
}

</style>
<style scoped>
@import "../../assets/css/common.css";
</style>
